import React from "react";
import styled from "styled-components";
import theme from "../theme/theme.json";
import { Link } from "gatsby";

const Button = ({
  children,
  link,
  exLink,
  to,
  style,
  col,
  hoverCol,
  textCol,
  fs,
  ff,
  fw,
  br,
  display,
  padding,
  onClick,
  align,
}) => {
  return (
    <>
      {(() => {
        if (style === "outline") {
          if (link === "true") {
            return (
              <StyledOutlineLink
                display={display}
                br={br}
                onClick={onClick}
                bPadding={padding}
                bCol={col}
                bHoverCol={hoverCol}
                bTextCol={textCol}
                bFs={fs}
                bFf={ff}
                align={align}
                bFw={fw}
                to={`${to}`}
              >
                {children}
              </StyledOutlineLink>
            );
          } else if (exLink === "true") {
            return (
              <StyledOutlineLink
                display={display}
                br={br}
                onClick={onClick}
                bPadding={padding}
                bCol={col}
                bHoverCol={hoverCol}
                bTextCol={textCol}
                bFs={fs}
                bFf={ff}
                align={align}
                bFw={fw}
                to={to}
                target="_blank"
                rel="noopener"
              >
                {children}
              </StyledOutlineLink>
            );
          } else {
            return (
              <StyledOutlineButton
                display={display}
                br={br}
                onClick={onClick}
                bPadding={padding}
                bCol={col}
                bHoverCol={hoverCol}
                bTextCol={textCol}
                bFs={fs}
                bFf={ff}
                align={align}
                bFw={fw}
              >
                {children}
              </StyledOutlineButton>
            );
          }
        } else {
          if (link === "true") {
            return (
              <StyledLink
                display={display}
                br={br}
                onClick={onClick}
                bPadding={padding}
                bCol={col}
                bHoverCol={hoverCol}
                bTextCol={textCol}
                bFs={fs}
                align={align}
                bFf={ff}
                bFw={fw}
                to={`${to}`}
              >
                {children}
              </StyledLink>
            );
          } else if (exLink === "true") {
            return (
              <StyledLink
                display={display}
                br={br}
                onClick={onClick}
                bPadding={padding}
                bCol={col}
                bHoverCol={hoverCol}
                bTextCol={textCol}
                bFs={fs}
                align={align}
                bFf={ff}
                bFw={fw}
                to={to}
                target="_blank"
                rel="noopener"
              >
                {children}
              </StyledLink>
            );
          } else {
            return (
              <StyledButton
                display={display}
                br={br}
                onClick={onClick}
                bPadding={padding}
                bCol={col}
                bHoverCol={hoverCol}
                bTextCol={textCol}
                bFs={fs}
                align={align}
                bFf={ff}
                bFw={fw}
              >
                {children}
              </StyledButton>
            );
          }
        }
      })()}
    </>
  );
};

export default Button;

const StyledButton = styled.button`
  display: ${(props) => props.display};
  margin-left: ${(props) => props.align};
  margin-right: ${(props) => props.align};
  border-radius: ${(props) => props.br};
  padding: ${(props) => props.bPadding || "13px"};
  line-height: 1.25;
  font-size: ${(props) => props.bFs || theme.fs.lg};
  font-family: ${(props) => props.bFf || theme.ff.primary};
  font-weight: ${(props) => props.bFw || 700};
  background-color: ${(props) => props.bCol || theme.col.primary.low};
  color: ${(props) => props.bTextCol || theme.col.bw.w};
  width: max-content;
  transition: all 0.3s;
  & :hover {
    background-color: ${(props) => props.bHoverCol || theme.col.primary.light};
    cursor: pointer;
  }
`;

const StyledLink = styled((props) => <Link {...props} />)`
  display: ${(props) => props.display};
  margin-left: ${(props) => props.align};
  margin-right: ${(props) => props.align};
  border-radius: ${(props) => props.br};
  padding: ${(props) => props.bPadding || "13px"};
  text-decoration: none;
  line-height: 1.25;
  font-size: ${(props) => props.bFs || theme.fs.lg};
  font-family: ${(props) => props.bFf || theme.ff.primary};
  font-weight: ${(props) => props.bFw || 700};
  background-color: ${(props) => props.bCol || theme.col.primary.low};
  color: ${(props) => props.bTextCol || theme.col.bw.w};
  display: block;
  width: max-content;
  transition: all 0.3s;
  & :hover {
    background-color: ${(props) => props.bHoverCol || theme.col.primary.light};
    opacity: 1;
  }
`;

const StyledOutlineButton = styled.button`
  display: ${(props) => props.display};
  margin-left: ${(props) => props.align};
  margin-right: ${(props) => props.align};
  border-radius: ${(props) => props.br};
  padding: ${(props) => props.bPadding || "10px"};
  line-height: 1.25;
  font-size: ${(props) => props.bFs || theme.fs.lg};
  font-family: ${(props) => props.bFf || theme.ff.primary};
  font-weight: ${(props) => props.bFw || 700};
  color: ${(props) => props.bTextCol || theme.col.primary.low};
  border: 3px solid ${theme.col.primary.low};
  width: max-content;
  transition: all 0.3s;
  & :hover {
    color: ${(props) => props.bHoverCol || theme.col.primary.light};
    border: 3px solid ${(props) => props.bHoverCol || theme.col.primary.light};
    cursor: pointer;
  }
`;

const StyledOutlineLink = styled((props) => <Link {...props} />)`
  display: ${(props) => props.display};
  margin-left: ${(props) => props.align};
  margin-right: ${(props) => props.align};
  border-radius: ${(props) => props.br};
  padding: ${(props) => props.bPadding || "10px"};
  text-decoration: none;
  line-height: 1.25;
  font-size: ${(props) => props.bFs || theme.fs.lg};
  font-family: ${(props) => props.bFf || theme.ff.primary};
  font-weight: ${(props) => props.bFw || 700};
  color: ${(props) => props.bTextCol || theme.col.primary.low};
  border: 3px solid ${theme.col.primary.low};
  display: block;
  width: max-content;
  transition: all 0.3s;
  & :hover {
    opacity: 1;
    border: 3px solid ${(props) => props.bHoverCol || theme.col.primary.light};
    color: ${(props) => props.bHoverCol || theme.col.primary.light};
  }
`;
