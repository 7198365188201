import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../theme/theme.json";
import Owl from "../comp-layouts/Owl";
import { graphql, Link, useStaticQuery } from "gatsby";
import Button from "../Button";

const getData = graphql`
  {
    allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "publications" } }
        frontmatter: { date: { gte: "2015", lt: "2016" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            collection
            slug
          }
          frontmatter {
            authors
            date(formatString: "MMMM Do, YYYY")
            description
            title
          }
        }
      }
    }
  }
`;

const PostSection = () => {
  const data = useStaticQuery(getData);
  const posts = data.allMarkdownRemark.edges;

  return (
    <>
      <PostGrid>
        {posts.map(({ node }) => (
          <PostGridItem key={node.id}>
            <PostLink to={`/${node.fields.collection}${node.fields.slug}`}>
              <PostTitle>{node.frontmatter.title}</PostTitle>

              <PostMeta>
                <PostDate>{node.frontmatter.date}</PostDate>
                <PostAuthors>{node.frontmatter.authors}</PostAuthors>
              </PostMeta>
            </PostLink>
            <PostDescription>
              {node.frontmatter.description}{" "}
              <PostLink to={`/${node.fields.collection}${node.fields.slug}`}>
                Publication Details »
              </PostLink>
            </PostDescription>
          </PostGridItem>
        ))}
      </PostGrid>
    </>
  );
};

export default PostSection;

const ButtonPlacer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const PostGrid = styled.div`
  display: grid;
  grid-gap: 40px;
  padding-bottom: 60px;
`;

const PostGridItem = styled.div`
  position: relative;
  padding: 15px 20px;
  border-left: ${theme.col.bluegrey[200]} solid 4px;
  display: grid;
  @media (min-width: 768px) {
    grid-template-columns: 1.3fr 3fr;
    gap: 30px;
  }
`;

const PostTitle = styled.span`
  color: ${theme.col.bw.b};
  font-size: 1.1rem;
  display: inline-block;
  position: relative;
  top: 3px;
  margin-right: 15px;
`;

const PostMeta = styled.div`
  margin: 10px 0;
`;

const PostDate = styled.time`
  font-weight: bold;
  display: inline-block;
  background: ${theme.col.primary.dark};
  color: ${theme.col.bw.w};
  margin-right: 15px;
  padding: 4px 8px;
  font-size: ${theme.fs.sm};
`;

const PostAuthors = styled.span`
  font-weight: bold;
  display: inline-block;
  color: ${theme.col.primary.low};
  font-size: ${theme.fs.sm};
  margin-top: 15px;
`;

const PostDescription = styled.p``;

const PostLink = styled((props) => <Link {...props} />)`
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
`;
