import React, { useState } from "react";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import styled from "styled-components";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";
import Button from "../components/Button";
import theme from "../theme/theme.json";
import Pubs2024 from "../components/site/Pubs2024";
import Pubs2023 from "../components/site/Pubs2023";
import Pubs2022 from "../components/site/Pubs2022";
import Pubs2021 from "../components/site/Pubs2021";
import Pubs2020 from "../components/site/Pubs2020";
import Pubs2019 from "../components/site/Pubs2019";
import Pubs2018 from "../components/site/Pubs2018";
import Pubs2017 from "../components/site/Pubs2017";
import Pubs2016 from "../components/site/Pubs2016";
import Pubs2015 from "../components/site/Pubs2015";
import Pubs2014 from "../components/site/Pubs2014";
import Pubs2013 from "../components/site/Pubs2013";
import Pubs2012 from "../components/site/Pubs2012";
import Pubs2011 from "../components/site/Pubs2011";
import Pubs2010 from "../components/site/Pubs2010";
import Pubs2009 from "../components/site/Pubs2009";

const Research = () => {
  const [showYear, setShowYear] = useState(2024);
  return (
    <Layout>
      <SEO
        title="Publications & Marine Plastics Research"
        description="Adrift Lab's publications cover topics including impacts of plastic pollution on plants, animals, and aquatic environments, and biology and ecology of seabirds."
      />
      <Container mt="60px" mb="20px">
        <Owl>
          <h1>Publications</h1>
          <p>
            Our publications cover topics including impacts of plastic pollution on plants, animals, and aquatic
            environments, and biology and ecology of seabirds.
          </p>
          <ButtonContainer>
               <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2024 ? "outline" : "solid"}
              onClick={() => setShowYear(2024)}
            >
              2024
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2023 ? "outline" : "solid"}
              onClick={() => setShowYear(2023)}
            >
              2023
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2022 ? "outline" : "solid"}
              onClick={() => setShowYear(2022)}
            >
              2022
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2021 ? "outline" : "solid"}
              onClick={() => setShowYear(2021)}
            >
              2021
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2020 ? "outline" : "solid"}
              onClick={() => setShowYear(2020)}
            >
              2020
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2019 ? "outline" : "solid"}
              onClick={() => setShowYear(2019)}
            >
              2019
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2018 ? "outline" : "solid"}
              onClick={() => setShowYear(2018)}
            >
              2018
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2017 ? "outline" : "solid"}
              onClick={() => setShowYear(2017)}
            >
              2017
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2016 ? "outline" : "solid"}
              onClick={() => setShowYear(2016)}
            >
              2016
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2015 ? "outline" : "solid"}
              onClick={() => setShowYear(2015)}
            >
              2015
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2014 ? "outline" : "solid"}
              onClick={() => setShowYear(2014)}
            >
              2014
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2013 ? "outline" : "solid"}
              onClick={() => setShowYear(2013)}
            >
              2013
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2012 ? "outline" : "solid"}
              onClick={() => setShowYear(2012)}
            >
              2012
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2011 ? "outline" : "solid"}
              onClick={() => setShowYear(2011)}
            >
              2011
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2010 ? "outline" : "solid"}
              onClick={() => setShowYear(2010)}
            >
              2010
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showYear == 2009 ? "outline" : "solid"}
              onClick={() => setShowYear(2009)}
            >
              2009
            </Button>
          </ButtonContainer>
        </Owl>
      </Container>
      <Container width="1024px" mb="50px">
        <div style={{ height: 20 }} />
{showYear == 2024 && <Pubs2024 />}
        {showYear == 2023 && <Pubs2023 />}
        {showYear == 2022 && <Pubs2022 />}
        {showYear == 2021 && <Pubs2021 />}
        {showYear == 2020 && <Pubs2020 />}
        {showYear == 2019 && <Pubs2019 />}
        {showYear == 2018 && <Pubs2018 />}
        {showYear == 2017 && <Pubs2017 />}
        {showYear == 2016 && <Pubs2016 />}
        {showYear == 2015 && <Pubs2015 />}
        {showYear == 2014 && <Pubs2014 />}
        {showYear == 2013 && <Pubs2013 />}
        {showYear == 2012 && <Pubs2012 />}
        {showYear == 2011 && <Pubs2011 />}
        {showYear == 2010 && <Pubs2010 />}
        {showYear == 2009 && <Pubs2009 />}
      </Container>
    </Layout>
  );
};

export default Research;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-top: 0;
  }
`;
